import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
	{
		label: t('Home'),
		// icon: 'Link2Icon',
		icon: 'HomeIcon',
	    href: '/',
		},
		
		/* items: [
		   
			{
			label: t('Fantom'),
			href: 'https://ftm.howswap.com/',
			},
				
		], */
		
		
      
		
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0xdAa64420e769fae36CCaA78e26024FE9f583e9D8',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
 
 
   {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, 
  
  
  {
		label: t('More'),
		icon: 'MoreIcon',

		items: [
				{
				label: t('Info'),
				icon: 'InfoIcon',
				href: 'https://info.howswap.com',
				},
				
				
				
				{
				label: t('Docs'),
				icon: 'InfoIcon',
				href: 'https://docs.howswap.com/',
				}, 
				
				/* {
				label: t('CMC'),
				icon: 'InfoIcon',
				href: 'https://coinmarketcap.com/currencies/howinu/',
				}, 
				
				{
				label: t('Coingecko'),
				icon: 'InfoIcon',
				href: 'https://www.coingecko.com/en/coins/howinu',
				},

				{
				label: t('Exchange'),
				icon: 'InfoIcon',
				href: 'https://www.coingecko.com/en/exchanges/howswap',
				},
 */
				{
				label: t('Geckoterminal'),
				icon: 'InfoIcon',
				href: 'https://www.geckoterminal.com/bsc/howswap/pools',
				},	

				/* {
				label: t('Binance'),
				icon: 'InfoIcon',
				href: 'https://www.binance.com/en/price/howinu',
				},	

				{
				label: t('ByBit'),
				icon: 'InfoIcon',
				href: 'https://www.bybit.com/en/coin-price/howinu/',
				},	

				{
				label: t('Coinbase'),
				icon: 'InfoIcon',
				href: 'https://www.coinbase.com/price/howinu',
				},		

				{
				label: t('Bitget'),
				icon: 'InfoIcon',
				href: 'https://www.bitget.com/price/howinu',
				},	

				{
				label: t('Gate'),
				icon: 'InfoIcon',
				href: 'https://www.gate.io/price/howinu-how',
				},		 */
	
				
		],},
		
		
 
  
				
				
				
				
				 
				
				 
				
				
				
				
		
  
       
 
	
	
	
	
	

]

export default config
